import {socialMedia} from "@/assets/social_media"

export const artists = {
    paululuh: {
      name: "Paululuh",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "paululuh.bsky.social",
        },
        {
          instagram: socialMedia.instagram.url + "paululuh",
        },
        {
          telegram: socialMedia.telegram.url + "pauululuh",
        },
        {
          twitter: socialMedia.twitter.url + "pauululuh",
        },
      ]
    },
    mespeth: {
      name: "Mespeth",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "mespeth.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "mespeth",
        },
        {
          telegram: socialMedia.telegram.url + "mespeth_art",
        },
        {
          tumblr: socialMedia.tumblr.url + "loveapologist",
        },
        {
          twitter: socialMedia.twitter.url + "mespeth_art",
        },
      ]
    },
    akuva: {
      name: "Akuva",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "akuva.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "akuva",
        },
        {
          tumblr: socialMedia.tumblr.url + "akuvaart",
        },
        {
          twitter: socialMedia.twitter.url + "akuvaart",
        },
      ]
    },
    gabrianne: {
      name: "Gabrianne",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "gabriannearts.bsky.social",
        },
        {
          twitter: socialMedia.twitter.url + "gabriannearts",
        },
      ]
    },
    aprion: {
      name: "Aprion",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "chillytokage.bsky.social",
        },
        {
          twitter: socialMedia.twitter.url + "chilly_tokage",
        },
      ]
    },
    graylynx: {
      name: "Graylynx",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "graylynx",
        },
      ]
    },
    ruffusbleu: {
      name: "Ruffus",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "ruffusbleu.bsky.social",
        },
        {
          twitter: socialMedia.twitter.url + "ruffusbleu",
        },
      ]
    },
    aleksika: {
      name: "AleksikaShvets",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "aleksikashvets.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "aleksikashvets",
        },
      ]
    },
    luradura: {
      name: "Lura Dura",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "LURAdura",
        },
        {
          twitter: socialMedia.twitter.url + "LURA94417389",
        },
      ]
    },
    arcane: {
      name: "Arcane",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "arcane.red",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "arcanewolfdragon",
        },
        {
          telegram: socialMedia.telegram.url + "ArcaneArt",
        },
        {
          twitter: socialMedia.twitter.url + "_Arcan3_",
        },
      ]
    },
    fjollettigeren: {
      name: "FjolletTigeren",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "fjollettigeren.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "fjollettigeren",
        },
        {
          twitter: socialMedia.twitter.url + "FjolletTigeren",
        },
      ]
    },
    flaich: {
      name: "Flaich",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "flaich",
        },
      ]
    },
    midnightdancer: {
      name: "Midnight Dancer",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "midnightdancer",
        },
      ]
    },
    mmare: {
      name: "Mmare",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "mmare",
        },
        {
          twitter: socialMedia.twitter.url + "NsfwMare",
        },
      ]
    },
    katsumizi: {
      name: "Katsumizi",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "katsumizi.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "katsumizi",
        },
        {
          twitter: socialMedia.twitter.url + "katsumizi",
        },
      ]
    },
    sadzarya: {
      name: "Sad Zarya",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "sadzarya",
        },
        {
          twitter: socialMedia.twitter.url + "sad_zarya",
        },
      ]
    },
    aduran: {
      name: "Aduran",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "aduran.bsky.social",
        },
        {
          twitter: socialMedia.twitter.url + "OnePotatoATime",
        },
      ]
    },
    cerberain: {
      name: "Cerberain",
      socialMedia: [
        {
          furaffinity: socialMedia.furaffinity.url + "cerberain",
        },
        {
          twitter: socialMedia.twitter.url + "cerberain",
        },
      ]
    },
    kousuracon: {
      name: "KousuRaCon",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "kousuracon.bsky.social",
        },
        {
          telegram: socialMedia.telegram.url + "Bodega7",
        },
        {
          twitter: socialMedia.twitter.url + "KousuRaCon",
        },
      ]
    },
    stupidshepherd: {
      name: "Stupidsheperd",
      socialMedia: [
        {
          telegram: socialMedia.telegram.url + "StupidShepArt",
        },
        
      ]
    },
    gedebuk: {
      name: "Gedebuk",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "gedebuk.bsky.social",
        },
        {
          twitter: socialMedia.twitter.url + "Gedebuk_Fox",
        }
        
      ]
    },
    skylerie: {
      name: "Skylerie",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "skylerie.bsky.social",
        },
        {
          instagram: socialMedia.instagram.url + "skyleriie",
        },
        {
          telegram: socialMedia.telegram.url + "skylerie",
        },
        {
          twitter: socialMedia.twitter.url + "Skypawz",
        }
      ]
    },
    reku: {
      name: "Reku",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "rekubirb.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "rekuillustration",
        },
        {
          twitter: socialMedia.twitter.url + "RekuArt",
        }
      ]
    },
    garoline: {
      name: "Garoline",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "garoline.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "kerotzuki",
        },
        {
          telegram: socialMedia.telegram.url + "Garoline",
        },
        {
          twitter: socialMedia.twitter.url + "GarolineArt",
        }
      ]
    },
    lordrex: {
      name: "LordRex",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "lordrex.bsky.social",
        },
        {
          telegram: socialMedia.telegram.url + "MetallosaurusRex",
        }
      ]
    },
    kousen: {
      name: "Kousen",
      socialMedia: [
        {
          bsky: socialMedia.bsky.url + "kousenzephyr.bsky.social",
        },
        {
          furaffinity: socialMedia.furaffinity.url + "kousen",
        },
        {
          telegram: socialMedia.telegram.url + "KousensArt",
        },
        {
          tumblr: socialMedia.tumblr.url + "kousenzephyr",
        }
      ]
    }
  };